import { LOGIN_USER, LOGIN_USER_FAILURE, LOGIN_USER_SUCCESS, LOGOUT_USER } from './authActions'
import { Action, AuthData } from './authActions'

export type State = {
    isAuthenticated: boolean
    isAuthenticating: boolean
    statusText: null | string
    publishingHouse: Record<string, unknown>
    data: AuthData | null | undefined
    token?: any
    userName?: any
}
const initialState: State = {
    isAuthenticated: false,
    isAuthenticating: false,
    statusText: null,
    publishingHouse: {},
    data: undefined,
}
export default (state: State = initialState, action: Action) => {
    switch (action.type) {
        case LOGIN_USER:
            return { ...state, isAuthenticating: true, isAuthenticated: false }

        case LOGIN_USER_SUCCESS:
            return {
                ...state,
                isAuthenticating: false,
                isAuthenticated: true,
                data: action.authPayload,
            }

        case LOGIN_USER_FAILURE:
            return { ...initialState }

        case LOGOUT_USER:
            return { ...initialState }

        default:
            return state
    }
}
