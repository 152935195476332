import classnames from 'classnames'
import html2canvas from 'html2canvas'
import { useState } from 'react'
import { Helmet } from 'react-helmet'
import { NavLink } from 'react-router-dom'

import AccountCircle from '@mui/icons-material/AccountCircle'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import MenuIcon from '@mui/icons-material/Menu'
import { AppBar, Divider, IconButton, Menu, MenuItem, Toolbar, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

import { AuthData } from 'Common/auth/authActions'
import { useAppbarTitleStore } from 'Common/store/AppBarTitleStore'
import { isOpusAdmin } from 'Common/utils/user/auth'

import UserFeedbackDialog from './UserFeedbackDialog'

const PREFIX = 'LATEST_VERSION_SEEN_BY_USER'

const classes = {
    appBar: `${PREFIX}-appBar`,
    appBarShift: `${PREFIX}-appBarShift`,
    grow: `${PREFIX}-grow`,
    menuButton: `${PREFIX}-menuButton`,
    drawer: `${PREFIX}-drawer`,
    sectionDesktop: `${PREFIX}-sectionDesktop`,
    sectionMobile: `${PREFIX}-sectionMobile`,
    subNavigationOpen: `${PREFIX}-subNavigationOpen`,
    noIcon: `${PREFIX}-noIcon`,
    hide: `${PREFIX}-hide`,
    accountMenu: `${PREFIX}-accountMenu`,
}

const Root = styled('div')(({ theme }) => ({
    [`& .${classes.appBar}`]: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },

    [`& .${classes.appBarShift}`]: {
        [theme.breakpoints.up('md')]: {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth - 10}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
    },

    [`& .${classes.grow}`]: {
        flexGrow: 1,
    },

    [`& .${classes.menuButton}`]: {
        [theme.breakpoints.up('sm')]: {
            marginLeft: 12,
            marginRight: 36,
        },
    },

    [`& .${classes.drawer}`]: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },

    [`& .${classes.sectionDesktop}`]: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'block',
        },
    },

    [`& .${classes.sectionMobile}`]: {
        display: 'none',
        [theme.breakpoints.down('lg')]: {
            display: 'block',
        },
    },

    [`& .${classes.subNavigationOpen}`]: {
        transform: 'rotateZ(90deg)',
    },

    [`& .${classes.noIcon}`]: {
        marginLeft: '20px',
    },

    [`& .${classes.hide}`]: {
        display: 'none',
    },

    [`& .${classes.accountMenu}`]: {
        whiteSpace: 'nowrap',
    },
}))

const drawerWidth = 240
export const LATEST_VERSION_SEEN_BY_USER = 'notice-latest-version-seen-by-user-v1'
export const LATEST_RELEASE = '2019-06-18'
type Props = {
    userInfo: AuthData
    settings: any
    auth: any
    open: boolean
    handleDrawerOpen: () => void
    mobileSubNavigationOpen: boolean
    hasSubmenu: boolean
    toggleMobileSubNavigation: () => void
    logout: () => void
}

const PrimaryAppBar = (props: Props) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null | undefined>(null)
    const [showFeedbackDialog, setShowFeedbackDialog] = useState<boolean>(false)

    const handleProfileMenuOpen = (event: React.SyntheticEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
    }

    const handleShowFeedbackDialog = (shouldShow: boolean) => {
        if (shouldShow === true) {
            setTimeout(() => {
                html2canvas(document.body).then((canvas: HTMLCanvasElement) => {
                    const base64image = canvas.toDataURL('image/png')
                    window.sessionStorage.setItem('feedbackScreenshot', base64image)
                    setShowFeedbackDialog(true)
                })
            }, 1000)
        } else {
            setShowFeedbackDialog(false)
        }
        handleMenuClose()
    }

    const {
        auth,
        handleDrawerOpen,
        open,
        logout,
        userInfo,
        toggleMobileSubNavigation,
        hasSubmenu,
    } = props
    const isMenuOpen = Boolean(anchorEl)
    const givenName = (userInfo && userInfo.given_name) || ''
    const familyName = (userInfo && userInfo.family_name) || ''
    const opusUserId = (userInfo && userInfo.opus_user_id) || ''
    const title = useAppbarTitleStore((state) => state.appTitle)
    return (
        <Root>
            <Helmet>
                <title>{title + ' - Mockingbird'}</title>
            </Helmet>
            <AppBar
                sx={{ display: 'flex', displayPrint: 'none' }}
                position="fixed"
                key="app-bar"
                className={classnames(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar disableGutters={!open}>
                    <IconButton
                        color="inherit"
                        aria-label="Open drawer"
                        onClick={handleDrawerOpen}
                        className={classnames(classes.menuButton, classes.sectionDesktop, {
                            [classes.hide]: open,
                        })}
                        size="large"
                    >
                        <ChevronRightIcon />
                    </IconButton>
                    <IconButton
                        color="inherit"
                        aria-label="Open sub menu"
                        onClick={() => toggleMobileSubNavigation()}
                        className={classnames(classes.menuButton, classes.sectionMobile, {
                            [classes.hide]: !hasSubmenu,
                        })}
                        size="large"
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        variant="h6"
                        color="inherit"
                        noWrap
                        className={classnames({
                            [classes.noIcon]: !hasSubmenu,
                        })}
                    >
                        {title}
                    </Typography>
                    <div key="grow" className={classes.grow} />
                    <div key="desktop" className={classes.accountMenu}>
                        <IconButton
                            key="menu-button"
                            aria-owns={isMenuOpen ? 'material-appbar' : undefined}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            className={classnames(classes.menuButton)}
                            color="inherit"
                            size="large"
                        >
                            <AccountCircle />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            <Menu
                key="menu"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={isMenuOpen}
                onClose={handleMenuClose}
            >
                <MenuItem
                    disabled={!isOpusAdmin(auth)}
                    component={NavLink}
                    to={`/admin/users/${opusUserId}`}
                >{`${givenName} ${familyName}`}</MenuItem>
                {isOpusAdmin(auth) ? <Divider /> : null}
                <MenuItem onClick={() => handleShowFeedbackDialog(true)}>
                    Send us feedback!
                    <small
                        style={{
                            color: '#3aa7bd',
                            display: 'inline-block',
                            marginTop: '-20px',
                            marginLeft: '-5px',
                            transform: 'rotate(27deg)',
                        }}
                    >
                        beta
                    </small>
                </MenuItem>
                <Divider />
                <MenuItem onClick={logout}>Logout</MenuItem>
            </Menu>
            {showFeedbackDialog ? (
                <UserFeedbackDialog
                    {...{ userInfo }}
                    closeDialog={() => handleShowFeedbackDialog(false)}
                />
            ) : null}
        </Root>
    )
}
export default PrimaryAppBar
