import { environments } from '../../config'

let currentEnvironment
export type EnvironmentKey = 'testBf' | 'testWs' | 'testNfg' | 'testNok'

export type ServiceKey =
    | 'identityserver'
    | 'users'
    | 'concept'
    | 'contact'
    | 'metadata'
    | 'work'
    | 'assets'
    | 'calculation'
    | 'royalty'
    | 'distribution'
    | 'storage'
    | 'schilling'

export type FeatureKey = 'oneflow' | 'erpIntegration'

export type SettingKey = 'currency'

export type Environment = {
    endpoints: {
        identityserver: string
        users: string
        concept: string
        contact: string
        metadata: string
        work: string
        assets: string
        calculation: string
        royalty: string | undefined
        distribution: string | undefined
        storage: string | undefined
        schilling: string | undefined
    }
    features: {
        oneflow: boolean
        erpIntegration: boolean
    }
    settings: {
        currency: string
    }
}

export function loadEnvironment(): Promise<Environment> {
    return currentEnvironment
        ? Promise.resolve(currentEnvironment)
        : new Promise((resolve, reject) => {
            loadConfig()
                .then((config) => {
                    currentEnvironment = config
                    return resolve(currentEnvironment)
                })
                .catch(() => {
                    if (import.meta.env.VITE_CLIENT_ENV) {
                        currentEnvironment = environments[import.meta.env.VITE_CLIENT_ENV]
                        return resolve(currentEnvironment)
                    }

                    reject(
                        'No env found: Please add it to window.environment.env or VITE_CLIENT_ENV in .env.local',
                    )
                })
        })
}

function loadConfig(): Promise<Environment> {
    return new Promise((resolve, reject) => {
        const fetchConfig = fetch('/config', { method: 'GET' })
        fetchConfig
            .then((response) => {
                return response.json()
            })
            .then((config) => {
                return resolve(config)
            })
            .catch(() => {
                return reject('could not load /config')
            })
    })
}

export function hasFeature(featureKey: FeatureKey): boolean {
    if (!currentEnvironment) new Error('environment not loaded')
    return currentEnvironment.features[featureKey]
}

export function getEnvironment(): Environment {
    if (!currentEnvironment) new Error('environment not loaded')
    return currentEnvironment
}
export const wrappService = (serviceKey: string) => {
    if (!currentEnvironment) new Error('Environment not loaded')
    return (uri = '') => {
        return `${currentEnvironment['endpoints'][serviceKey]}${uri}`
    }
}
export const urlWrapper = (serviceKey: string) => (url: string) => () =>
    wrappService(serviceKey)(url)
export const services = {
    identityserver: wrappService('identityserver'),
    users: wrappService('users'),
    concept: wrappService('concept'),
    contact: wrappService('contact'),
    metadata: wrappService('metadata'),
    work: wrappService('work'),
    assets: wrappService('assets'),
    calculation: wrappService('calculation'),
    royalty: wrappService('royalty'),
    distribution: wrappService('distribution'),
    storage: wrappService('storage'),
    schilling: wrappService('schilling'),
}
export const servicesLoaders = {
    identityserver: urlWrapper('identityserver'),
    users: urlWrapper('users'),
    concept: urlWrapper('concept'),
    contact: urlWrapper('contact'),
    metadata: urlWrapper('metadata'),
    work: urlWrapper('work'),
    assets: urlWrapper('assets'),
    calculation: urlWrapper('calculation'),
    royalty: wrappService('royalty'),
    distribution: wrappService('distribution'),
    schilling: wrappService('schilling'),
}
export const getSetting = (settingKey: SettingKey) => {
    if (!currentEnvironment) new Error('environment not loaded')
    return currentEnvironment['settings'][settingKey]
}
export const getLabel = (inputKey: string) => {
    if (!currentEnvironment) new Error('environment not loaded')
    if (currentEnvironment['labels'] != undefined) {
        return currentEnvironment['labels'].find((item) => item.key === inputKey)?.value
    } else return null
}
