import { filter, flow, getOr, isUndefined, keys, pickBy, uniq } from 'lodash/fp'

import { AuthData } from 'Common/auth/authActions'
import { State as AuthState } from 'Common/auth/authReducer'

import { ClaimType, ClaimValue } from '../../types'

export const getMyPublishingHouseIds = (auth: AuthState, claimType: ClaimType): any =>
    flow(
        keys,
        filter((key) => key !== 'private'),
    )(getOr({}, `data.opus_user.access.${claimType}`, auth))
export const getMyPublishingHouseIdsApprover = (auth: AuthState, module: ClaimType): any =>
    flow(
        pickBy((claims: any) => claims.includes('approver')),
        keys,
        filter((key) => key !== 'private'),
    )(getOr({}, `data.opus_user.access.${module}`, auth))

const getPublishingHouseFromClaimValue = (claimValue) => {
    const [baseValue, groupAndHouse] = claimValue.split('|')
    const [publishingHouseGroup, publishingHouse] = groupAndHouse.split(':')
    return publishingHouse
}

export const getMyUserId = (auth: AuthState) => getOr(null, 'data.opus_user.id', auth)
export const getMyContactIds = (auth: AuthState) => getOr([], 'data.opus_contact_id', auth)
export const getMyLanguageCodes = (
    auth: { data: AuthData },
    publishingHouseGroups: any,
): string[] => {
    const myPublishingHouseGroups = getOr([], 'data.opus_user.publishingHouseGroups', auth)
    const languageCodes = publishingHouseGroups
        .filter((phg) => myPublishingHouseGroups.map((p) => p.id).includes(phg.id))
        .map((phg) => {
            const { cultureInfo } = phg
            const lang = cultureInfo.split('-')
            return lang ? lang[0] : false
        })
    return languageCodes
}

const allDefined = (...inputs) => inputs.every((input) => !isUndefined(input))

export const hasClaimWithValue = (auth: AuthState, claimName: ClaimType, claimValue: ClaimValue) =>
    allDefined(claimName, claimValue) &&
    getOr([], 'data.opus_user.claims', auth).some(
        (claim) => claim.name === claimName && claim.value === claimValue,
    )

export const isCustomerAdmin = (auth: AuthState): boolean => {
    return (
        hasClaimWithValue(auth, 'opus_user', 'admin') ||
        hasClaimWithValue(auth, 'opus_user', 'customeradmin')
    )
}

export const isOpusAdmin = (auth: AuthState) =>
    ['opus_user', 'opus_metadata'].some((claim: any) => hasClaimWithValue(auth, claim, 'admin'))
export const isContactPowerUser = (auth: AuthState) =>
    ['opus_contacts'].some((claim: any) => hasClaimWithBaseValue(auth, claim, 'power_user'))

export const isWorkPowerUser = (auth: AuthState) =>
    ['opus_work'].some((claim: any) => hasClaimWithBaseValue(auth, claim, 'power_user'))

export const hasClaim = (auth: AuthState, claimName: ClaimType) =>
    !isUndefined(claimName) &&
    getOr([], 'data.opus_user.claims', auth).some((claim) => claim.name === claimName)

const getClaimValueStart = (claimValue) => {
    const [claimvalueStart] = claimValue.split('|')
    return claimvalueStart
}

export const hasClaimWithBaseValue = (
    auth?: AuthState,
    claimName?: ClaimType,
    claimValueStartWith?: any,
) => {
    if (!auth || !claimValueStartWith) return false
    return (
        !isUndefined(claimName) &&
        getOr([], 'data.opus_user.claims', auth).some(
            (claim) =>
                claim.name === claimName && getClaimValueStart(claim.value) === claimValueStartWith,
        )
    )
}
export const hasClaimContainingValue = (
    auth: AuthState,
    claimName: ClaimType,
    claimValue: string,
) =>
    allDefined(claimName, claimValue) &&
    getOr([], 'data.opus_user.claims', auth).some(
        (claim) => claim.name === claimName && claim.value.includes(claimValue),
    )
export const getUserClaimsByBaseValue = (auth, baseValue) =>
    getOr([], 'data.opus_user.claims', auth).filter(
        ({ value }) => getClaimValueStart(value) === baseValue,
    )
export const getDistinctPublishingHousesFromClaims = (claims) =>
    uniq(claims.map(({ value }) => getPublishingHouseFromClaimValue(value)))
