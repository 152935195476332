import { Suspense, lazy } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { State as AuthState } from 'Common/auth/authReducer'
import ErrorPage from 'Common/error/ErrorPage'

import Spinner, { SPINNER_SIZES } from '../loader/Spinner'
import PrivateRoute from './PrivateRoute'

const DashboardIndexLoader = lazy(() => import('Root/containers/Index'))
const ConceptsSearchLoader = lazy(() => import('Concept/containers/Concepts'))
const ConceptLoader = lazy(() => import('Concept/containers/Concept'))
const AdminUsersLoader = lazy(() => import('Admin/containers/users/Users'))
const AdminPriceUpdateLoader = lazy(() => import('Admin/containers/erp/PriceUpdate'))
const AdminUserLoader = lazy(() => import('Admin/containers/users/User'))
const MetaDataTypesLoader = lazy(() => import('Admin/containers/metadata/MetaDataTypes'))
const SearchEditionsLoader = lazy(() => import('Work/containers/Productions'))
const SearchWorksLoader = lazy(() => import('Work/containers/Works'))
const SearchActivitiesLoader = lazy(() => import('Work/containers/Activities'))
const WorkActivityLoader = lazy(() => import('Work/components/Activities/ActivityDetails'))
const BookLoader = lazy(() => import('Work/containers/Book'))
const DownloadLoader = lazy(() => import('Common/components/downloads/DownloadsContainer'))
const ContactListsLoader = lazy(() => import('Contact/containers/Lists'))
const ContactListLoader = lazy(() => import('Contact/containers/List'))
const AssetsSearchLoader = lazy(() => import('Asset/containers/Assets'))
const AssetLoader = lazy(() => import('Asset/containers/Asset'))
const ContactsSearchLoader = lazy(() => import('Contact/containers/Contacts'))
const ContactLoader = lazy(() => import('Contact/containers/Contact'))
const ImportTransactionLoader = lazy(() => import('Imports/containers/Transactions'))
const ImportStockChangesLoader = lazy(() => import('Imports/containers/StockChanges'))
const ImportRetailerOrdersLoader = lazy(() => import('Imports/containers/RetailerOrders'))
const RoyaltyAgreementLoader = lazy(() => import('Royalty/containers/AgreementContainer'))
const AdminLoader = lazy(() => import('Admin/containers/AdminContainer'))
const RoyaltyMainLoader = lazy(() => import('Royalty/containers/RoyaltyMainContainer'))

type Props = {
    auth?: AuthState
}

function Routes({ auth }: Props) {
    return (
        <Suspense fallback={<Spinner size={SPINNER_SIZES.LARGE} />}>
            <Switch>
                <PrivateRoute exact auth={auth} path="/my-work" component={DashboardIndexLoader} />
                <PrivateRoute exact auth={auth} path="/" component={DashboardIndexLoader} />
                <Route path="/dashboard" render={() => <Redirect to="/my-work" />} />
                <PrivateRoute
                    exact
                    auth={auth}
                    restrictToClaims={['opus_concept']}
                    path="/concept/search"
                    component={ConceptsSearchLoader}
                />
                <PrivateRoute
                    auth={auth}
                    restrictToClaims={['opus_concept']}
                    path="/concept/:conceptId"
                    component={ConceptLoader}
                />
                <PrivateRoute
                    auth={auth}
                    restrictToClaims={['opus_concept']}
                    path="/concept/:conceptId/:section"
                    component={ConceptLoader}
                />
                <PrivateRoute
                    auth={auth}
                    restrictToClaims={['opus_concept']}
                    path="/concept/:conceptId/:section/:editionId"
                    component={ConceptLoader}
                />
                <PrivateRoute exact auth={auth} path="/admin/users" component={AdminUsersLoader} />
                <PrivateRoute
                    exact
                    auth={auth}
                    path="/admin/price-update"
                    component={AdminPriceUpdateLoader}
                />
                <PrivateRoute auth={auth} path="/admin/users/:userId" component={AdminUserLoader} />
                <PrivateRoute
                    auth={auth}
                    path="/admin/users/:userId/:section"
                    component={AdminUserLoader}
                />
                <PrivateRoute auth={auth} path="/admin" component={AdminLoader} />
                <PrivateRoute auth={auth} path="/metadata" component={MetaDataTypesLoader} />
                <PrivateRoute
                    exact
                    auth={auth}
                    restrictToClaims={['opus_work']}
                    path="/book/editions"
                    component={SearchEditionsLoader}
                />
                <PrivateRoute
                    exact
                    auth={auth}
                    restrictToClaims={['opus_work']}
                    path="/book/works"
                    component={SearchWorksLoader}
                />
                <PrivateRoute
                    exact
                    auth={auth}
                    restrictToClaims={['opus_work']}
                    path="/book/activities"
                    component={SearchActivitiesLoader}
                />
                <PrivateRoute
                    exact
                    auth={auth}
                    restrictToClaims={['opus_work']}
                    path="/book/activities/:activityId"
                    component={WorkActivityLoader}
                />
                <PrivateRoute
                    auth={auth}
                    restrictToClaims={['opus_work']}
                    path="/book/:workId/edition/:productionId"
                    component={BookLoader}
                />
                <PrivateRoute
                    auth={auth}
                    restrictToClaims={['opus_work']}
                    path="/book/:workId"
                    component={BookLoader}
                />
                <PrivateRoute
                    exact
                    auth={auth}
                    restrictToClaims={['opus_asset']}
                    path="/assets"
                    component={AssetsSearchLoader}
                />
                <PrivateRoute exact auth={auth} path="/mydownloads" component={DownloadLoader} />
                <PrivateRoute
                    auth={auth}
                    restrictToClaims={['opus_asset']}
                    path="/assets/:id"
                    component={AssetLoader}
                />
                <PrivateRoute
                    exact
                    auth={auth}
                    restrictToClaims={['opus_contacts']}
                    path="/contact/lists"
                    component={ContactListsLoader}
                />
                <PrivateRoute
                    exact
                    auth={auth}
                    restrictToClaims={['opus_contacts']}
                    path="/contact"
                    component={ContactsSearchLoader}
                />
                <PrivateRoute
                    auth={auth}
                    restrictToClaims={['opus_contacts']}
                    path="/contact/lists/:id"
                    component={ContactListLoader}
                />
                <PrivateRoute
                    auth={auth}
                    restrictToClaims={['opus_contacts']}
                    path="/contact/:contactId"
                    component={ContactLoader}
                />
                <PrivateRoute
                    auth={auth}
                    restrictToClaims={['opus_royalty']}
                    path="/imports/createretailerorders"
                    component={ImportRetailerOrdersLoader}
                />
                <PrivateRoute
                    auth={auth}
                    restrictToClaims={['opus_royalty']}
                    path="/imports/importtransaction"
                    component={ImportTransactionLoader}
                />
                <PrivateRoute
                    auth={auth}
                    restrictToClaims={['opus_royalty']}
                    path="/imports/importstockchanges"
                    component={ImportStockChangesLoader}
                />
                <PrivateRoute
                    auth={auth}
                    restrictToClaims={['opus_royalty']}
                    path="/royalty/agreement/:id"
                    component={RoyaltyAgreementLoader}
                />
                <PrivateRoute
                    auth={auth}
                    restrictToClaims={['opus_royalty']}
                    path="/royalty"
                    component={RoyaltyMainLoader}
                />
                <PrivateRoute path="*" component={ErrorPage} />
            </Switch>
        </Suspense>
    )
}

export default Routes
