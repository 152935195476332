import { Asset } from 'Common/assets/AssetsActions'
import { services } from 'Common/environment'
import createErrorMessages from 'Common/messages/createErrorMessages'
import { Edition, Work } from 'Common/types'
import request from 'Common/utils/net/request'

import messages from '../messages'

export const GET_WORK = 'GET_WORK'
export const GET_WORK_REQUEST = 'GET_WORK_REQUEST'
export const GET_WORK_SUCCESS = 'GET_WORK_SUCCESS'
export const GET_WORK_FAILURE = 'GET_WORK_FAILURE'
export const GET_WORK_SILENT = 'GET_WORK_SILENT'
export const GET_WORK_SILENT_REQUEST = 'GET_WORK_SILENT_REQUEST'
export const GET_WORK_SILENT_SUCCESS = 'GET_WORK_SILENT_SUCCESS'
export const GET_WORK_SILENT_FAILURE = 'GET_WORK_SILENT_FAILURE'
export const GET_WORK_THEMAS = 'GET_WORK_THEMAS'
export const GET_WORK_THEMAS_REQUEST = 'GET_WORK_THEMAS_REQUEST'
export const GET_WORK_THEMAS_SUCCESS = 'GET_WORK_THEMAS_SUCCESS'
export const GET_WORK_THEMAS_FAILURE = 'GET_WORK_THEMAS_FAILURE'
export const GET_WORK_ROYALTY = 'GET_WORK_ROYALTY'
export const GET_WORK_ROYALTY_REQUEST = 'GET_WORK_ROYALTY_REQUEST'
export const GET_WORK_ROYALTY_SUCCESS = 'GET_WORK_ROYALTY_SUCCESS'
export const GET_WORK_ROYALTY_FAILURE = 'GET_WORK_ROYALTY_FAILURE'
export const PUT_WORK_ROYALTY = 'PUT_WORK_ROYALTY'
export const PUT_WORK_ROYALTY_REQUEST = 'PUT_WORK_ROYALTY_REQUEST'
export const PUT_WORK_ROYALTY_SUCCESS = 'PUT_WORK_ROYALTY_SUCCESS'
export const PUT_WORK_ROYALTY_FAILURE = 'PUT_WORK_ROYALTY_FAILURE'
export const GET_WORK_COSTS = 'GET_WORK_COSTS'
export const GET_WORK_COSTS_REQUEST = 'GET_WORK_COSTS_REQUEST'
export const GET_WORK_COSTS_SUCCESS = 'GET_WORK_COSTS_SUCCESS'
export const GET_WORK_COSTS_FAILURE = 'GET_WORK_COSTS_FAILURE'
export const PUT_WORK_COSTS = 'PUT_WORK_COSTS'
export const PUT_WORK_COSTS_REQUEST = 'PUT_WORK_COSTS_REQUEST'
export const PUT_WORK_COSTS_SUCCESS = 'PUT_WORK_COSTS_SUCCESS'
export const PUT_WORK_COSTS_FAILURE = 'PUT_WORK_COSTS_FAILURE'
export const POST_WORK = 'POST_WORK'
export const POST_WORK_REQUEST = 'POST_WORK_REQUEST'
export const POST_WORK_SUCCESS = 'POST_WORK_SUCCESS'
export const POST_WORK_FAILURE = 'POST_WORK_FAILURE'
export const PATCH_WORK = 'PATCH_WORK'
export const PATCH_WORK_REQUEST = 'PATCH_WORK_REQUEST'
export const PATCH_WORK_SUCCESS = 'PATCH_WORK_SUCCESS'
export const PATCH_WORK_FAILURE = 'PATCH_WORK_FAILURE'
export const GET_WORK_COMMUNICATION_PLAN = 'GET_WORK_COMMUNICATION_PLAN'
export const GET_WORK_COMMUNICATION_PLAN_REQUEST = 'GET_WORK_COMMUNICATION_PLAN_REQUEST'
export const GET_WORK_COMMUNICATION_PLAN_SUCCESS = 'GET_WORK_COMMUNICATION_PLAN_SUCCESS'
export const GET_WORK_COMMUNICATION_PLAN_FAILURE = 'GET_WORK_COMMUNICATION_PLAN_FAILURE'
export const PATCH_WORK_COMMUNICATION_PLAN = 'PATCH_WORK_COMMUNICATION_PLAN'
export const PATCH_WORK_COMMUNICATION_PLAN_REQUEST = 'PATCH_WORK_COMMUNICATION_PLAN_REQUEST'
export const PATCH_WORK_COMMUNICATION_PLAN_SUCCESS = 'PATCH_WORK_COMMUNICATION_PLAN_SUCCESS'
export const PATCH_WORK_COMMUNICATION_PLAN_FAILURE = 'PATCH_WORK_COMMUNICATION_PLAN_FAILURE'
export const CONNECT_ASSET_WORK = 'CONNECT_ASSET_WORK'
export const CONNECT_ASSET_WORK_REQUEST = 'CONNECT_ASSET_WORK_REQUEST'
export const CONNECT_ASSET_WORK_SUCCESS = 'CONNECT_ASSET_WORK_SUCCESS'
export const CONNECT_ASSET_WORK_FAILURE = 'CONNECT_ASSET_WORK_FAILURE'
export const DISCONNECT_ASSET_WORK = 'DISCONNECT_ASSET_WORK'
export const DISCONNECT_ASSET_WORK_REQUEST = 'DISCONNECT_ASSET_WORK_REQUEST'
export const DISCONNECT_ASSET_WORK_SUCCESS = 'DISCONNECT_ASSET_WORK_SUCCESS'
export const DISCONNECT_ASSET_WORK_FAILURE = 'DISCONNECT_ASSET_WORK_FAILURE'
export const GET_WORK_ASSETS = 'GET_WORK_ASSETS'
export const GET_WORK_ASSETS_REQUEST = 'GET_WORK_ASSETS_REQUEST'
export const GET_WORK_ASSETS_SUCCESS = 'GET_WORK_ASSETS_SUCCESS'
export const GET_WORK_ASSETS_FAILURE = 'GET_WORK_ASSETS_FAILURE'
export const POST_WORK_ERP = 'POST_WORK_ERP'
export const POST_WORK_ERP_REQUEST = 'POST_WORK_ERP_REQUEST'
export const POST_WORK_ERP_SUCCESS = 'POST_WORK_ERP_SUCCESS'
export const POST_WORK_ERP_FAILURE = 'POST_WORK_ERP_FAILURE'
export const POST_WORK_CONCEPT = 'POST_WORK_CONCEPT'
export const POST_WORK_CONCEPT_REQUEST = 'POST_WORK_CONCEPT_REQUEST'
export const POST_WORK_CONCEPT_SUCCESS = 'POST_WORK_CONCEPT_SUCCESS'
export const POST_WORK_CONCEPT_FAILURE = 'POST_WORK_CONCEPT_FAILURE'
export const POST_WORK_MAIN_EDITION = 'POST_WORK_MAIN_EDITION'
export const POST_WORK_MAIN_EDITION_REQUEST = 'POST_WORK_MAIN_EDITION_REQUEST'
export const POST_WORK_MAIN_EDITION_SUCCESS = 'POST_WORK_MAIN_EDITION_SUCCESS'
export const POST_WORK_MAIN_EDITION_FAILURE = 'POST_WORK_MAIN_EDITION_FAILURE'
export const POST_WORK_CANCEL = 'POST_WORK_CANCEL'
export const POST_WORK_CANCEL_REQUEST = 'POST_WORK_CANCEL_REQUEST'
export const POST_WORK_CANCEL_SUCCESS = 'POST_WORK_CANCEL_SUCCESS'
export const POST_WORK_CANCEL_FAILURE = 'POST_WORK_CANCEL_FAILURE'
const GET_WORK_ERROR_MESSAGES = createErrorMessages(['WORK_NOT_FOUND', 'WORK_FORBIDDEN'], messages)

export const getWork = (id: number) => (dispatch) =>
    dispatch({
        type: GET_WORK,
        payload: {
            data: {
                id,
            },
            promise: request({
                url: services.work(`/v1/works/${id}`),
                method: 'GET',
                errorMessages: GET_WORK_ERROR_MESSAGES,
            }),
        },
    })
export const getWorkThemas = (workId: number) => (dispatch) =>
    dispatch({
        type: GET_WORK_THEMAS,
        payload: {
            data: workId,
            promise: request({
                method: 'GET',
                url: services.work(`/v1/works/${workId}/themas`),
            }),
        },
    })
export const getWorkCosts = (workId: number) => (dispatch) =>
    dispatch({
        type: GET_WORK_COSTS,
        payload: {
            data: workId,
            promise: request({
                method: 'GET',
                url: services.work(`/v1/works/${workId}/costs`),
            }),
        },
    })
export const putWorkCosts = (workId: number, workCosts) => (dispatch) =>
    dispatch({
        type: PUT_WORK_COSTS,
        payload: {
            data: workId,
            promise: request({
                method: 'PUT',
                url: services.work(`/v1/works/${workId}/costs`),
                body: workCosts,
            }),
        },
    })
const GET_WORK_SILENT_ERROR_MESSAGES = createErrorMessages(
    ['WORK_NOT_FOUND', 'WORK_FORBIDDEN'],
    messages,
)
// for refreshing work in the background
export const getWorkSilent = (id: number) => (dispatch) =>
    dispatch({
        type: GET_WORK_SILENT,
        payload: {
            data: {
                id,
            },
            promise: request({
                url: services.work(`/v1/works/${id}`),
                method: 'GET',
                errorMessages: GET_WORK_SILENT_ERROR_MESSAGES,
            }),
        },
    })

const POST_WORK_ERROR_MESSAGES = createErrorMessages([], messages)
export const postWork = (body: Work) => (dispatch) =>
    dispatch({
        type: POST_WORK,
        meta: {},
        payload: {
            promise: request({
                method: 'POST',
                body: body,
                url: services.work(`/v1/works`),
                errorMessages: POST_WORK_ERROR_MESSAGES,
            }),
        },
    })
const PATCH_WORK_ERROR_MESSAGES = createErrorMessages(
    [
        'WORK_NOT_FOUND',
        'WORK_FORBIDDEN',
        'WORK_ALREADY_CONNECTED_TO_ERP',
        'PRODUCTION_BAD_ERP_STATUS_CODE',
        'PRODUCTION_DISCOUNT_NUMBER_NOT_FOUND',
    ],
    messages,
)
export const patchWork =
    (workId: number, patchSet, productionIdsInAdditionToWork?: any) => (dispatch) => {
        const url = productionIdsInAdditionToWork
            ? services.work(`/v1/works/${workId}?addOnProductions=${productionIdsInAdditionToWork}`)
            : services.work(`/v1/works/${workId}`)
        return dispatch({
            type: PATCH_WORK,
            meta: {
                workId,
            },
            payload: {
                promise: request({
                    method: 'PATCH',
                    url,
                    body: patchSet,
                    errorMessages: PATCH_WORK_ERROR_MESSAGES,
                }),
            },
        })
    }
const GET_WORK_ASSETS_ERROR_MESSAGES = createErrorMessages(
    ['WORK_NOT_FOUND', 'WORK_FORBIDDEN'],
    messages,
)
export const getWorkAssets = (workId: number) => (dispatch) =>
    dispatch({
        type: GET_WORK_ASSETS,
        payload: {
            data: {
                workId,
            },
            promise: request({
                method: 'GET',
                url: services.work(`/v1/works/${workId}/assets`),
                errorMessages: GET_WORK_ASSETS_ERROR_MESSAGES,
            }),
        },
    })
const CONNECT_ASSET_WORK_ERROR_MESSAGES = createErrorMessages(
    [
        'WORK_NOT_FOUND',
        'WORK_FORBIDDEN',
        'WORK_INVALID_ASSET_TYPE',
        'ASSET_NOT_FOUND',
        'ASSET_ALREADY_EXISTS',
    ],
    messages,
)
export const connectAssetToWork = (production: Edition, asset: Asset) => {
    const { workId } = production
    return (dispatch) =>
        dispatch({
            type: CONNECT_ASSET_WORK,
            meta: {
                workId,
                asset: {
                    id: 0,
                },
            },
            payload: {
                data: workId,
                promise: request({
                    url: services.work(`/v1/works/${workId}/assets`),
                    method: 'POST',
                    body: asset,
                    errorMessages: CONNECT_ASSET_WORK_ERROR_MESSAGES,
                }),
            },
        })
}
const DISCONNECT_ASSET_WORK_ERROR_MESSAGES = createErrorMessages(
    ['WORK_NOT_FOUND', 'WORK_FORBIDDEN', 'ASSET_NOT_FOUND'],
    messages,
)
export const disconnectAssetFromWork = (production: Edition, asset: Asset) => {
    const { workId } = production
    const assetId = asset.id
    return (dispatch) =>
        dispatch({
            type: DISCONNECT_ASSET_WORK,
            meta: {
                workId,
                asset: {
                    id: 0,
                },
            },
            payload: {
                data: workId,
                promise: request({
                    url: services.work(`/v1/works/${workId}/assets/${assetId}`),
                    method: 'DELETE',
                    errorMessages: DISCONNECT_ASSET_WORK_ERROR_MESSAGES,
                }),
            },
        })
}
