import { getOr, sortBy } from 'lodash/fp'

import { requestStatus } from 'Common/utils/net/statuses'

import {
    DELETE_MEMBER_WORK_SUCCESS,
    POST_MEMBER_WORK_FAILURE,
    POST_MEMBER_WORK_REQUEST,
    POST_MEMBER_WORK_SUCCESS,
    PUT_MEMBER_WORK_SUCCESS,
} from '../actions/memberActions'
import {
    CONNECT_ASSET_WORK_REQUEST,
    CONNECT_ASSET_WORK_SUCCESS,
    DISCONNECT_ASSET_WORK_REQUEST,
    DISCONNECT_ASSET_WORK_SUCCESS,
    GET_WORK_ASSETS_FAILURE,
    GET_WORK_ASSETS_REQUEST,
    GET_WORK_ASSETS_SUCCESS,
    GET_WORK_FAILURE,
    GET_WORK_REQUEST,
    GET_WORK_ROYALTY_FAILURE,
    GET_WORK_ROYALTY_REQUEST,
    GET_WORK_ROYALTY_SUCCESS,
    GET_WORK_SILENT_SUCCESS,
    GET_WORK_SUCCESS,
    GET_WORK_THEMAS_FAILURE,
    GET_WORK_THEMAS_REQUEST,
    GET_WORK_THEMAS_SUCCESS,
    PATCH_WORK_FAILURE,
    PATCH_WORK_REQUEST,
    PATCH_WORK_SUCCESS,
    POST_WORK_ERP_FAILURE,
    POST_WORK_ERP_REQUEST,
    POST_WORK_ERP_SUCCESS,
    POST_WORK_MAIN_EDITION_FAILURE,
    POST_WORK_MAIN_EDITION_REQUEST,
    POST_WORK_MAIN_EDITION_SUCCESS,
    PUT_WORK_ROYALTY_SUCCESS,
} from '../actions/workActions'

const initialState = {
    status: {
        getStatus: undefined,
        getError: undefined,
    },
    assets: [],
    assetStatus: {
        getStatus: undefined,
        getError: undefined,
    },
    themasStatus: {},
    data: undefined,
    royaltyStatus: undefined,
}
export default (state = initialState, action) => {
    switch (action.type) {
        case GET_WORK_ASSETS_REQUEST:
            return {
                ...state,
                assets: [],
                assetStatus: { ...state.status, getStatus: requestStatus.request, getError: null },
            }

        case GET_WORK_ASSETS_FAILURE:
            return {
                ...state,
                assets: [],
                assetStatus: {
                    ...state.status,
                    getStatus: requestStatus.failure,
                    getError: action.payload,
                },
            }

        case GET_WORK_ASSETS_SUCCESS:
            return {
                ...state,
                assets: action.payload.json,
                assetStatus: { ...state.status, assetStatus: requestStatus.success },
            }

        case DISCONNECT_ASSET_WORK_REQUEST:
        case CONNECT_ASSET_WORK_REQUEST:
            return {
                ...state,
                data: { ...state.data, coverImageAssetId: 0, refreshThumbnail: false },
            }

        case DISCONNECT_ASSET_WORK_SUCCESS: {
            const assets = action.payload.json ? action.payload.json : null
            return { ...state, assets, data: { ...state.data, refreshThumbnail: true } }
        }

        case CONNECT_ASSET_WORK_SUCCESS: {
            const assets = action.payload.json ? action.payload.json : null
            const shouldRefreshThumbnail = assets.includes((element) => element.id === '9') // remove hardcoded value

            return {
                ...state,
                assets,
                data: {
                    ...state.data,
                    coverImageAssetId: action.meta.asset.id,
                    refreshThumbnail: shouldRefreshThumbnail,
                },
            }
        }

        case GET_WORK_REQUEST:
            return {
                ...state,
                data: null,
                status: { ...state.status, getStatus: requestStatus.request, getError: null },
            }

        case GET_WORK_THEMAS_REQUEST:
            return {
                ...state,
                themasStatus: {
                    ...state.themasStatus,
                    getStatus: requestStatus.request,
                    getError: null,
                },
            }

        case GET_WORK_ROYALTY_REQUEST:
            return {
                ...state,
                royaltyStatus: {
                    ...state.royaltyStatus,
                    getStatus: requestStatus.request,
                    getError: null,
                },
            }

        case GET_WORK_SILENT_SUCCESS:
        case GET_WORK_SUCCESS:
            return {
                ...state,
                data: { ...state.data, ...action.payload.json },
                status: { ...state.status, getStatus: requestStatus.success },
            }

        case GET_WORK_THEMAS_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    themas: sortBy('sortOrder', getOr([], 'payload.json', action)).filter(
                        (t) => !!t.themaCodeType,
                    ),
                },
                themasStatus: { ...state.themasStatus, getStatus: requestStatus.success },
            }

        case GET_WORK_ROYALTY_SUCCESS:
        case PUT_WORK_ROYALTY_SUCCESS:
            return {
                ...state,
                data: { ...state.data, royalty: getOr({}, 'payload.json', action) },
                royaltyStatus: { ...state.royaltyStatus, getStatus: requestStatus.success },
            }

        case GET_WORK_FAILURE:
            return {
                ...state,
                status: {
                    ...state.status,
                    getStatus: requestStatus.failure,
                    getError: action.payload,
                },
            }

        case GET_WORK_THEMAS_FAILURE:
            return {
                ...state,
                themasStatus: {
                    ...state.themasStatus,
                    getStatus: requestStatus.failure,
                    getError: action.payload,
                },
            }

        case GET_WORK_ROYALTY_FAILURE:
            return {
                ...state,
                royaltyStatus: {
                    ...state.royaltyStatus,
                    getStatus: requestStatus.failure,
                    getError: action.payload,
                },
            }

        case PATCH_WORK_REQUEST:
            return {
                ...state,
                status: { ...state.status, patchStatus: requestStatus.request, patchError: null },
            }

        case PATCH_WORK_SUCCESS:
            return {
                ...state,
                data: { ...state.data, ...action.payload.json },
                status: { ...state.status, patchStatus: requestStatus.success },
            }

        case PATCH_WORK_FAILURE:
            return {
                ...state,
                status: {
                    ...state.status,
                    patchStatus: requestStatus.failure,
                    patchError: action.payload,
                },
            }

        case POST_WORK_ERP_REQUEST:
            return {
                ...state,
                status: { ...state.status, postStatus: requestStatus.request, postError: null },
            }

        case POST_WORK_ERP_SUCCESS:
            return {
                ...state,
                data: { ...state.data, ...action.payload.json },
                status: { ...state.status, postStatus: requestStatus.success },
            }

        case POST_WORK_ERP_FAILURE:
            return {
                ...state,
                status: {
                    ...state.status,
                    postStatus: requestStatus.failure,
                    postError: action.payload,
                },
            }

        case POST_MEMBER_WORK_REQUEST:
            return {
                ...state,
                status: { ...state.status, postStatus: requestStatus.request, postError: null },
            }

        case PUT_MEMBER_WORK_SUCCESS:
        case DELETE_MEMBER_WORK_SUCCESS:
        case POST_MEMBER_WORK_SUCCESS:
            return {
                ...state,
                data: { ...state.data, ...action.payload.json },
                status: { ...state.status, postStatus: requestStatus.success },
            }

        case POST_MEMBER_WORK_FAILURE:
            return {
                ...state,
                status: {
                    ...state.status,
                    postStatus: requestStatus.failure,
                    postError: action.payload,
                },
            }

        case POST_WORK_MAIN_EDITION_REQUEST:
            return {
                ...state,
                status: { ...state.status, postStatus: requestStatus.request, postError: null },
            }

        case POST_WORK_MAIN_EDITION_SUCCESS:
            return {
                ...state,
                data: { ...state.data, ...action.payload.json },
                status: { ...state.status, postStatus: requestStatus.success },
            }

        case POST_WORK_MAIN_EDITION_FAILURE:
            return {
                ...state,
                status: {
                    ...state.status,
                    postStatus: requestStatus.failure,
                    postError: action.payload,
                },
            }

        default:
            return state
    }
}
