import { Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { Variant } from '@mui/material/styles/createTypography'

type Props = {
    noGrid?: boolean
    label?: string
    value?: string | number | Date | React.ReactNode
    required?: boolean
    variant?: Variant
}

const DisplayField = (props: Props) => {
    const { label, value, variant = 'body1', required = false, noGrid } = props
    return (
        <>
            {noGrid ? (
                <>
                    <Typography variant="caption" color="text.secondary" sx={{ marginBottom: 0.5 }}>
                        {label} {required ? '*' : ''}
                    </Typography>
                    <Typography sx={{ whiteSpace: 'pre-line' }} variant={variant}>
                        {value != undefined ? value : '-'}
                    </Typography>
                </>
            ) : (
                <Grid xs={12}>
                    <Typography variant="body2" color="text.secondary" sx={{ marginBottom: 0.5 }}>
                        {label} {required ? '*' : ''}
                    </Typography>
                    <Typography sx={{ whiteSpace: 'pre-line' }} variant={variant}>
                        {value != undefined ? value : '-'}
                    </Typography>
                </Grid>
            )}
        </>
    )
}

export default DisplayField
