import { requestStatus } from 'Common/utils/net/statuses'

import {
    GET_MULTI_SEARCH_FAILURE,
    GET_MULTI_SEARCH_REQUEST,
    GET_MULTI_SEARCH_SUCCESS,
} from './multiSearchAction'

const initialState = {
    data: [],
    status: undefined,
}
export default (state = initialState, action) => {
    switch (action.type) {
        case GET_MULTI_SEARCH_REQUEST: {
            return { ...state, status: requestStatus.request }
        }

        case GET_MULTI_SEARCH_SUCCESS: {
            return {
                data: action.payload.map((_, index) => ({
                    ..._.json,
                    type: action.meta.searchQueries[index].objectType,
                })),
                status: requestStatus.success,
            }
        }

        case GET_MULTI_SEARCH_FAILURE:
            return { ...state, status: requestStatus.failure }

        default:
            return state
    }
}
